<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ readonly ? "Просмотр" : "Редактирование" }}
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :config="{ readonly, dense: true }" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm],
  props: {
    dataTable: Array,
    id: Number,
    value: Boolean,
    RO: Array,
    type: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.documentGoodsIncome,
      api: "",
      price_unit: null,
      rowEdit: {},
      rows: [],
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.formDetail));
      model.forEach((el) => {
        if (this.RO.includes(el.name) && this.data[el.name] !== null) {
          el.readonly = true;
        }
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.m?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
      });
      return model;
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    id(v) {
      if (v) this.row = this.dataTable.find((el) => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find((el) => el.id == this.id);
      }
    },
    "data.amount"() {
      if (!this.loading) this.calcPrice(1);
    },
  },
  methods: {
    afterFetchData() {
      // this.calcPrice(0);
      this.loading = false;
    },
    resetForm() {
      this.price_unit = null;
    },
    calcPrice(v) {
      this.data.value = this.data.value / this.data.amount;
    },
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable));
      if (this.id) {
        this.rowEdit = this.rows.find((el) => el.id == this.id);
        for (const name in this.rowEdit) {
          this.$set(this.data, name, this.rowEdit[name]);
        }
      }
    },

    post() {
      let rows = [];
      this.rows.forEach((r) => {
        if (r.id == this.id) {
          rows.push(this.data);
        } else {
          rows.push(r);
        }
      });
      if (!this.id) {
        rows.push(this.data);
      }
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>
